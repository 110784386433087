import axiosInstance from "auth/FetchInterceptor";
import { DOCUMENT_TYPE_ID } from "constants/index";

const { INTERNAL_API } = require("constants/ApiConstant");

const InternalService = {};

InternalService.updateSignatureProcess = function (formData) {
  let baseURL = process.env.REACT_APP_HRM_ENDPOINT_URL;

  switch (formData.documentType) {
    case DOCUMENT_TYPE_ID.KskPhuongAnKinhDoanh:
    case DOCUMENT_TYPE_ID.KskThanhLyHopDong:
    case DOCUMENT_TYPE_ID.DonHangKsk:
      baseURL = process.env.REACT_APP_CRM_ENDPOINT_URL;
      break;
    case DOCUMENT_TYPE_ID.VanBanNoiBoDi:
    case DOCUMENT_TYPE_ID.QuanLyCongVanBenNgoaiDi:
    case DOCUMENT_TYPE_ID.QuanLyCongVanBenNgoaiDen:
    case DOCUMENT_TYPE_ID.PhieuQuyChe:
    case DOCUMENT_TYPE_ID.PhieuVanBan:
    case DOCUMENT_TYPE_ID.HoSoTaiLieu:
    case DOCUMENT_TYPE_ID.QuanLyDuAn:
      baseURL = process.env.REACT_APP_PROJECT_ENDPOINT_URL;
      break;
    case DOCUMENT_TYPE_ID.DeXuatMuaSam:
    case DOCUMENT_TYPE_ID.KeHoachMuaHang:  
      baseURL = process.env.REACT_APP_SALE_ENDPOINT_URL;
      break;
    case DOCUMENT_TYPE_ID.NhapHang:
    case DOCUMENT_TYPE_ID.XuatHang:
    case DOCUMENT_TYPE_ID.LuanChuyen:
    case DOCUMENT_TYPE_ID.BanBuon:
    case DOCUMENT_TYPE_ID.BanLe:
      baseURL = process.env.REACT_APP_PHARMA_ENDPOINT_URL;
      break;
    default:
      break;
  }

  return axiosInstance.post(INTERNAL_API, formData, {
    baseURL: baseURL,
  });
};

export default InternalService;

// REACT_APP_CRM_ENDPOINT_URL= https://crm-api.meditech.vn
// REACT_APP_HRM_ENDPOINT_URL= https://hrm-api.meditech.vn
// REACT_APP_IDENTITY_ENDPOINT_URL= https://identity-api.meditech.vn
// REACT_APP_PROJECT_ENDPOINT_URL= https://project-api.meditech.vn
// REACT_APP_SECRETKEY = 27SiA7zQelxg8Ih6XkjkggiSurOZlAjc
// REACT_APP_COMMON_ENDPOINT_URL= http://localhost:5500/
