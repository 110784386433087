import { Checkbox, Table } from "antd";
import React, { forwardRef, memo, useEffect } from "react";

const MediTable = forwardRef(function MediTable(
  {
    dataSource,
    tableColumns,
    onRow,
    onRenderFooter,
    enableSelectAll,
    rowKeyField = "id",
    // Callback area
    onRowSelectChange,
    bordered = false,
    components,
    getCheckboxProps,
    rowSelectionType = "checkbox",
    rowKeyValue = [],
    pageSize = 10,
    limitY = null,
  },
  ref
) {
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  useEffect(() => {
    if (!rowKeyValue) return;
    setSelectedRowKeys(rowKeyValue);
  }, [rowKeyValue]);

  // useEffect(() => {
  //   if (onRowSelectChange) onRowSelectChange(selectedRowKeys);
  // }, [onRowSelectChange, selectedRowKeys]);

  const handleSelect = (record, selected) => {
    let rowKeys = [];
    if (selected) {
      rowKeys = [...rowKeyValue, record[rowKeyField]];
      setSelectedRowKeys((keys) => [...keys, record[rowKeyField]]);
    } else {
      const index = rowKeyValue.indexOf(record[rowKeyField]);
      rowKeys = [
        ...rowKeyValue.slice(0, index),
        ...rowKeyValue.slice(index + 1),
      ];
      setSelectedRowKeys((keys) => [
        ...keys.slice(0, index),
        ...keys.slice(index + 1),
      ]);
    }
    if (onRowSelectChange) onRowSelectChange(rowKeys);
  };

  const toggleSelectAll = ({ target }) => {
    let rowKeys = [];
    let sourceIds = dataSource.map((x) => x[rowKeyField]);
    let oldIds = rowKeyValue.filter((x) => !sourceIds.some((y) => x === y));

    if (target.checked) {
      rowKeys = [...oldIds, ...sourceIds];
    } else {
      const length = sourceIds.length + oldIds.length;

      if (length === selectedRowKeys.length) {
        rowKeys = oldIds;
      } else {
        rowKeys = [...oldIds, ...sourceIds].filter((x) => x);
      }
    }

    setSelectedRowKeys(rowKeys);
    if (onRowSelectChange) onRowSelectChange(rowKeys);
  };

  const headerCheckbox = (
    <Checkbox
      checked={selectedRowKeys.length}
      indeterminate={
        selectedRowKeys.length > 0 && selectedRowKeys.length < dataSource.length
      }
      onChange={toggleSelectAll}
    />
  );

  const rowSelection = {
    selectedRowKeys,
    type: rowSelectionType,
    fixed: true,
    onSelect: rowSelectionType === "checkbox" && handleSelect,
    columnTitle: rowSelectionType === "checkbox" && headerCheckbox,
    onChange: (keys) => {
      if (rowSelectionType === "radio") {
        setSelectedRowKeys(keys);
        if (onRowSelectChange) onRowSelectChange(keys);
      }
    },
    getCheckboxProps: getCheckboxProps,
  };

  return (
    <Table
      components={components}
      rowSelection={enableSelectAll ? rowSelection : null}
      columns={tableColumns}
      dataSource={dataSource}
      scroll={{
        x: "max-content",
        y: limitY,
      }}
      tableLayout="fixed"
      rowKey={(item) => item[rowKeyField]}
      footer={onRenderFooter}
      bordered={bordered}
      onRow={onRow}
      pagination={{
        showSizeChanger: true,
        showTotal: (total, range) => `Hiển thị: ${range[1]} / ${total}`,
        defaultPageSizes: pageSize,
      }}
    />
  );
});

export default memo(MediTable);
