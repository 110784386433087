import axiosInstance from "auth/FetchInterceptor";
import {
  QUAN_LY_CONG_CHAM_CONG_API,
  QUAN_LY_CONG_THIET_LAP_API,
} from "constants/ApiConstant";
const QuanLyCongService = {};

QuanLyCongService.gridSearchCaLamViec = function (data) {
  return axiosInstance.post(
    `${QUAN_LY_CONG_THIET_LAP_API}/ca-lam-viec/search-grid`,
    data
  );
};

QuanLyCongService.getAllCaLamViec = function () {
  return axiosInstance.get(`${QUAN_LY_CONG_THIET_LAP_API}/ca-lam-viec/get-all`);
};

QuanLyCongService.getCaLamViecById = function (id) {
  return axiosInstance.get(
    `${QUAN_LY_CONG_THIET_LAP_API}/ca-lam-viec/get-by-id?id=${id}`
  );
};

QuanLyCongService.create = function (data) {
  return axiosInstance.post(`${QUAN_LY_CONG_THIET_LAP_API}/ca-lam-viec`, data);
};

QuanLyCongService.update = function (data) {
  return axiosInstance.put(`${QUAN_LY_CONG_THIET_LAP_API}/ca-lam-viec`, data);
};

QuanLyCongService.delete = function (id) {
  return axiosInstance.delete(
    `${QUAN_LY_CONG_THIET_LAP_API}/ca-lam-viec?id=${id}`
  );
};

QuanLyCongService.importBangChamCong = function (data) {
  return axiosInstance.post(
    `${QUAN_LY_CONG_THIET_LAP_API}/bang-cham-cong-import-excel`,
    data,
    { ContentType: "multipart/form-data" }
  );
};

QuanLyCongService.saveBangChamCongImport = function (data) {
  return axiosInstance.post(
    `${QUAN_LY_CONG_THIET_LAP_API}/save-bang-cham-cong-import`,
    data
  );
};

// Ký hiệu chấm công

QuanLyCongService.searchSymbol = function (search) {
  return axiosInstance.get(
    `${QUAN_LY_CONG_THIET_LAP_API}/Symbol/search${
      search ? `?search=${search}` : ""
    }`
  );
};

QuanLyCongService.upSertSymbol = function (data) {
  return axiosInstance.post(`${QUAN_LY_CONG_THIET_LAP_API}/Symbol`, data);
};

// Lý do nghỉ

QuanLyCongService.searchReason = function (search) {
  return axiosInstance.get(
    `${QUAN_LY_CONG_THIET_LAP_API}/Reason/search${
      search ? `?search=${search}` : ""
    }`
  );
};

QuanLyCongService.upSertReason = function (data) {
  return axiosInstance.post(`${QUAN_LY_CONG_THIET_LAP_API}/Reason`, data);
};

// Bảng chấm công

QuanLyCongService.searchDsBangCong = function (data) {
  return axiosInstance.post(`${QUAN_LY_CONG_CHAM_CONG_API}/search`, data);
};

QuanLyCongService.getByIdDsBangCong = function (id) {
  return axiosInstance.get(`${QUAN_LY_CONG_CHAM_CONG_API}?id=${id}`);
};

QuanLyCongService.addDsBangCong = function (data) {
  return axiosInstance.post(QUAN_LY_CONG_CHAM_CONG_API, data);
};

QuanLyCongService.updateDsBangCong = function (data) {
  return axiosInstance.put(QUAN_LY_CONG_CHAM_CONG_API, data);
};

QuanLyCongService.deleteBangCong = function (id) {
  return axiosInstance.delete(`${QUAN_LY_CONG_CHAM_CONG_API}?id=${id}`);
};

QuanLyCongService.deleteBangCongNhanVien = function (id) {
  return axiosInstance.delete(
    `${QUAN_LY_CONG_CHAM_CONG_API}/deletenhanvien?id=${id}`
  );
};

QuanLyCongService.changeStateDsBangCong = function (id) {
  return axiosInstance.put(
    `${QUAN_LY_CONG_CHAM_CONG_API}/change-state?id=${id}`
  );
};

QuanLyCongService.searchChiTietBangCong = function (data) {
  return axiosInstance.post(
    `${QUAN_LY_CONG_CHAM_CONG_API}/chi-tiet/search`,
    data
  );
};

QuanLyCongService.upSertBc = function (data) {
  return axiosInstance.post(`${QUAN_LY_CONG_CHAM_CONG_API}/chi-tiet`, data);
};

QuanLyCongService.delNhanVienById = function (id) {
  return axiosInstance.delete(
    `${QUAN_LY_CONG_CHAM_CONG_API}/chi-tiet/delete?id=${id}`
  );
};

QuanLyCongService.export = function (data) {
  return axiosInstance.get(
    `${QUAN_LY_CONG_CHAM_CONG_API}/chi-tiet/export?id=${data.id}&isBangCong=${data.isBangCong}`
  );
};

QuanLyCongService.chotBangCong = function (data) {
  return axiosInstance.post(`${QUAN_LY_CONG_CHAM_CONG_API}/chot-cong`, data);
};

QuanLyCongService.rebuildResultCongNhanVien = function (data) {
  return axiosInstance.post(
    `${QUAN_LY_CONG_CHAM_CONG_API}/rebuild-result-bang-cong`,
    data
  );
};

export default QuanLyCongService;
