import axiosInstance from "auth/FetchInterceptor";
import {
  BANGCONG_API,
  BANG_CONG_TONG_HOP_API,
  CHI_TIET_BANG_CONG_API,
  THIET_LAP_BANG_CONG_API,
} from "constants/ApiConstant";
const bangCongService = {};

bangCongService.searchDsBangCong = function (data) {
  return axiosInstance.post(`${BANGCONG_API}/search`, data);
};

bangCongService.getByIdDsBangCong = function (id) {
  return axiosInstance.get(`${BANGCONG_API}?id=${id}`);
};

bangCongService.searchBangCongFormKhieuNai = function (id) {
  return axiosInstance.get(
    `${BANGCONG_API}/searchBangCongFormKhieuNai?bangCongId=${id}`
  );
};

bangCongService.addDsBangCong = function (data) {
  return axiosInstance.post(BANGCONG_API, data);
};

bangCongService.updateDsBangCong = function (data) {
  return axiosInstance.put(BANGCONG_API, data);
};

bangCongService.deleteBangCong = function (id) {
  return axiosInstance.delete(`${BANGCONG_API}?id=${id}`);
};

bangCongService.copyBangCong = function (id) {
  return axiosInstance.get(`${BANGCONG_API}/copy-bang-cong?id=${id}`);
};

bangCongService.changeStateDsBangCong = function (id) {
  return axiosInstance.put(`${BANGCONG_API}/change-state?id=${id}`);
};

bangCongService.searchChiTietBangCong = function (data) {
  return axiosInstance.post(`${CHI_TIET_BANG_CONG_API}/search`, data);
};

bangCongService.upSertChiTietBangCong = function (data) {
  return axiosInstance.post(`${CHI_TIET_BANG_CONG_API}`, data);
};

bangCongService.getBangCongSystem = function (branchId) {
  return axiosInstance.get(`${THIET_LAP_BANG_CONG_API}?branchId=${branchId}`);
};

bangCongService.delBangCongSystem = function (id) {
  return axiosInstance.delete(`${THIET_LAP_BANG_CONG_API}?id=${id}`);
};

bangCongService.upSertBangCongSystem = function (data) {
  return axiosInstance.post(`${THIET_LAP_BANG_CONG_API}`, data);
};

bangCongService.delNhanVienById = function (id) {
  return axiosInstance.delete(
    `${CHI_TIET_BANG_CONG_API}/xoa-nhan-vien?id=${id}`
  );
};

bangCongService.getTongHopNam = function (branchId) {
  return axiosInstance.get(`${BANG_CONG_TONG_HOP_API}?branchId=${branchId}`);
};

bangCongService.getTongHopNamByNam = function (data) {
  return axiosInstance.get(
    `${BANG_CONG_TONG_HOP_API}/get-by-year?nam=${data.nam}&branchId=${data.branchId}`
  );
};

bangCongService.exportBangCong = function (id) {
  return axiosInstance.post(
    `${BANGCONG_API}/export-bang-cong?bangCongId=${id}`
  );
};

bangCongService.exportBangCongTangCa = function (id) {
  return axiosInstance.post(
    `${BANGCONG_API}/export-bang-cong-tang-ca?bangCongId=${id}`
  );
};

bangCongService.exportBangCongTongHop = function (nam, branchId) {
  return axiosInstance.post(
    `${BANGCONG_API}/export-bang-tong-hop?nam=${nam}&branchId=${branchId}`);
};


bangCongService.checkBangCongNhanVien = function (id) {
  return axiosInstance.get(`${BANGCONG_API}/check-bang-cong?id=${id}`);
};

bangCongService.bangCongHisAsync = function (data) {
  return axiosInstance.post(`${BANGCONG_API}/bang-cong-his`, data);
};

bangCongService.upSertbangCongHisAsync = function (data, reqType) {
  const url =  reqType === 1? `${BANGCONG_API}/bang-cong-his/up-sert` :  (reqType === 2 ? `${BANGCONG_API}/bang-cong-his/update-cong-truc` : `${BANGCONG_API}/bang-cong-his/update-tangca`);
  return axiosInstance.post(url, data);
};

bangCongService.BangCongFormKhieuNaiChangeState = function (id) {
  return axiosInstance.post(
    `${BANGCONG_API}/khieu-nai-cong/change-state?id=${id}`
  );
};

export default bangCongService;
